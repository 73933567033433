import React, { useCallback, useEffect, useState } from "react";
import axios from "axios";
import "./App.css";
import { connect, useDispatch, useSelector } from "react-redux";
import { getBlogs } from "./Actions/blogs";
import ContentBlogLoader from "./Components/Loader/contentLoader";
import BodyBlogLoader from "./Components/Loader/bodyLoader";
import images_nodata from "../src/images/new-menu-bg/images_nodata.png";
import "../src/utils/style.scss";
import Note from "./utils/Note";
import { bindActionCreators } from "redux";
import { fetchTagList } from "./Actions/addTags";

function DemoOne({
  parasize,
  menuvalue,
  font_type,
  bgValue,
  bgparaValue,
  colorFont,
  blogs,
  lineHeight,
}) {
  const [success, setsuccess] = useState([]);
  const [getdatas, setgetdatas] = useState([]);
  const currentYear = new Date().getFullYear(); //
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getBlogs(setsuccess));
    dispatch(fetchTagList())
  }, []);

  useEffect(() => {
    if (blogs?.length !== 0) {
      setgetdatas(blogs);
      setsuccess(true);
    }
  }, [blogs]);

  if (success == false) {
    return (
      <div>
        <div className="contentLoader">
          <ContentBlogLoader />
        </div>
        <div className="skeletonLoader">
          <BodyBlogLoader />
        </div>
        <div className="skeletonLoader">
          <BodyBlogLoader />
        </div>
        <div className="skeletonLoader">
          <BodyBlogLoader />
        </div>
      </div>
    );
  }
  return (
    <div className="App" style={{overflowX:'auto',marginTop:40}}>
      {success == true
        ? getdatas
            .filter(
              (item) =>
                item.pageName === "blog" ||
                item.pageName === "reflections" ||
                item.pageName === "poems"
            )
            .map((item) => {
              const matches =
                item.title !== null ? item.title.match(/\bql-font\w*/) : "";
              const fontClass = matches ? matches[0] : "";
              return (
                <React.Fragment key={item.id}>
                  <div className="container-fluid post-container">
                    <div className="row">
                      <div className={`site-content ${font_type}`}>
                        <div className={`post-sec frame1 ${bgValue}`}>
                          {(item?.pageName === "poems" && item.note !== null) ||
                          (item?.pageName === "reflections" &&
                            item.note !== null) ? (
                            <div
                              className="note-container"
                              style={{
                                paddingTop: 0,
                                display: "flex",
                                flexDirection: "column",
                              }}
                            >
                            
                            {item?.pageName === "poems" && item.title !== null ? (
                              <div className="title-div-poem-note-pem">
                              <h1
                                class={`entry-title`}
                                dangerouslySetInnerHTML={{
                                  __html: item.title,
                                }}
                              ></h1>
                              </div>
                            ) : null}
                            
                              { item?.pageName === "reflections" && item.title !== null ? (
                                <h1
                                  class={`entry-title`}
                                  dangerouslySetInnerHTML={{
                                    __html: item.title,
                                  }}
                                ></h1>
                              ) : null}
                              
                              {(item?.pageName === "poems" &&
                                item.note !== null) ||
                              (item?.pageName === "reflections" &&
                                item.note !== null) ? (
                                <>
                                  {item?.pageName === "poems" ||
                                  item?.pageName === "reflections" ? (
                                    <div className="sticky-box">
                                      <Note
                                        note={item.note}
                                        bg_color={item.background_colour}
                                      />
                                    </div>
                                  ) : null}
                                </>
                              ) : null}
                            </div>
                          ) : (
                            <React.Fragment>
                            {item.title.length !== 11 ? (
                              <h1
                                class={`entry-tile`}
                                dangerouslySetInnerHTML={{
                                  __html: item.title,
                                }}
                              >
                              </h1>
                            ) : null}
                            </React.Fragment>
                          )}

                          {item.imageUrl ? (
                            <img className={`image1`} src={item.imageUrl}></img>
                          ) : (
                            ""
                          )}
                          <div
                            className="desc_wrapper"
                            style={{
                              paddingTop:
                                getdatas[0]?.pageName === "fairytale" &&
                                getdatas[0]?.title === null
                                  ? 0
                                  : 5,
                            }}
                          >
                          <div
                          style={{
                            fontSize: `${parasize}px`,
                            color: `#0F307A`,
                            width: "100%",
                            display: "inline-block",
                            whiteSpace: "pre-wrap",
                            alignSelf: "center",
                          }}
                          className={`entry-content ${bgparaValue} ${
                            item.space_number ? `entry-content_poem` : ""
                          }`} 
                          dangerouslySetInnerHTML={{
                            __html: item.description.replace(/&nbsp;/g, " "),
                          }}
                        ></div>
                          </div>
                        </div>
                        {item?.pageName === "poems" ||
                        item?.pageName === "reflections" ||
                        item?.pageName === "blog" ? (
                          <div
                            className={`poem-reflection-footer`}
                            style={{
                              fontSize: 12,
                              fontFamily: `${bgparaValue}`,
                            }}
                          >
                            <h>
                              © {currentYear} Ritu Dhillon All Rights Reserved.
                              {"     "}
                            </h>
                            <div>
                              {""}
                              <h> {item.place ? `${item.place},` : ""} </h>
                              <h className={`${fontClass}`}>
                                {item.year ? item.year : ""}
                              </h>
                            </div>
                          </div>
                        ) : null}
                      </div>
                    </div>
                  </div>
                 
        {/*<div style={{width: '100%', display: 'flex',marginRight:0}}> 
        <img src={logos_back} alt="Icon" width={300} height={110}   style={{
          objectFit: 'contain',
          marginLeft:'auto', 
          margin: "auto",
        }}/></div>*/}
                          <div class="separator"></div>
                </React.Fragment>
              );
            })
        : ""}
      {getdatas.length === 0 ? (
        <div
          className=""
          style={{
            alignSelf: "center",
            marginTop: 100,
            marginLeft: "auto",
            marginRight: "auto",
            display: "flex",
            flexDirection: "column",
          }}
        >
          <img
            src={images_nodata}
            alt=""
            width={100}
            height={100}
            style={{ margin: "auto" }}
          />
          <label
            style={{
              alignSelf: "center",
              marginTop: 30,
              marginLeft: "auto",
              marginRight: "auto",
              display: "flex",
              flexDirection: "column",
            }}
          >
            No blog found!
          </label>
        </div>
      ) : (
        ""
      )}
    </div>
  );
}

const mapStateToProps = (state) => ({
  blogs: state.enroll.blogs,
  tagsList: state.enroll.tagsList,
});
const mapDispatchToProps = (dispatch) => {
  return {
    actions: bindActionCreators(
      {
        fetchTagList
      },
      dispatch
    ),
    dispatch,
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(DemoOne);
