import React, { useState, useEffect } from 'react';
import './stickyCss.scss';
import { Icon } from '@iconify/react';
import { CloseOutlined } from '@ant-design/icons';

const StickyNote = ({ note, title, bg_color }) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const [initialContent, setInitialContent] = useState('');
  const [fullContent, setFullContent] = useState('');

  const maxContentLength = 13;

  const handleNoteClick = () => {
    setIsExpanded(!isExpanded);
  };

  const handleXButtonClick = (e) => {
    e.stopPropagation(); // Prevent the click event from bubbling up to the parent div
    setIsExpanded(false); // Collapse the note when X button is clicked
  };

  const truncateContent = (content) => {
    return content.length > 6 ? content.slice(0, 34) + '..........' : content;
  };
  

  useEffect(() => {
    setInitialContent(truncateContent(note));
    setFullContent(note);
  }, [note]);
  

  return (
    <div className={`sticky-note ${isExpanded ? 'expanded' : ''}`} onClick={handleNoteClick} style={{ backgroundColor: `${bg_color}` }}>
      <span className="entry-tit" style={{ fontWeight: 'bold', fontSize: 12, marginTop: isExpanded ?10:0}}>Poet's Comments</span>
      {isExpanded ? (
        <div>
          <CloseOutlined className="close-button" onClick={handleXButtonClick} style={{ position: 'absolute', top: '5px', right: '5px', cursor: 'pointer' }} /> {/* X button */}
          <div className='stick-content-p' dangerouslySetInnerHTML={{ __html: fullContent.replace(/<p>/g, '<p style="border-radius:10;padding-left:4px;font-size: 18px;padding-top:20px">') }}></div>
        </div>
      ) : (
        <div style={{ fontFamily: 'crackerBanana', borderRadius: 10, fontSize: 10}} dangerouslySetInnerHTML={{ __html: initialContent.replace(/<p>/g, '<p class="sticky-desc" style="color: #00000;font-size: 18px;height:20px;border-radius:4px;padding-left:4px;margin-top: 10px;">') }}></div>
      )}
    </div>
  );
};


export default StickyNote;
