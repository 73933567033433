/* eslint-disable no-unused-expressions */
/* eslint-disable no-lone-blocks */
import React, { useEffect, useState } from "react";
import { Modal, Form, Input, Button, Spin, Select, DatePicker } from "antd";
import ReactQuill, { Quill } from "react-quill";
import "quill-mention";
import "react-quill/dist/quill.snow.css";
import 'quill/modules/keyboard';
import axios from "axios";
import { Navigate, useParams, useLocation, Link } from "react-router-dom";
import editimage from "./images/edit.png";
import deleteimage from "./images/delete.png";
import { useDispatch } from "react-redux";
import { createBlogs, getBlogs } from "./Actions/blogs";
import { antIcon, deleteLoadIcon } from "./../src/Components/Loader/antIcon";
import {
  BoldOutlined,
  HomeOutlined,
  ItalicOutlined,
  UnderlineOutlined,
  UnlockOutlined,
} from "@ant-design/icons";
import moment from "moment";
import { API_URL } from "./config";
import Note from "../src/utils/Note.js";
import StickyNote from "./Components/pages/StickReflection.js";

const FormItem = Form.Item;
const { Option } = Select;

const Font = Quill.import("formats/font");
const FontSecond = Quill.import("formats/font");
// We do not add Aref Ruqaa since it is the default
Font.whitelist = [
  "madeEvolve",
  "dutchTulips",
  "quickgram",
  "crackerBanana",
  "nursery",
];

FontSecond.whitelist = [
  "madeEvolve",
  "dutchTulips",
  "quickgram",
  "crackerBanana",
  "nursery",
  "tuckers",
  "winterLove",
  "alishader",
  "serendipity",
  "bluehill",
  "bunchOfFlowers",
  "christmasInk",
  "fairygod",
  "misteriosDelAmor",
  "soybeanut",
  "spaceCraft",
  "helloPatty",
  "jathafaDemo"
];
Quill.register(Font, true);
Quill.register(FontSecond, true);

var Size = Quill.import("formats/size");
Size.whitelist = [
  "4px",
  "5px",
  "6px",
  "7px",
  "8px",
  "9px",
  "10px",
  "11px",
  "12px",
  "14px",
  "16px",
  "18px",
  "20px",
  "22px",
  "24px",
  "26px",
  "28px",
];

Quill.register(Size, true);

const Parchment = Quill.import("parchment");
const boxAttributor = new Parchment.Attributor.Class("box", "line", {
  scope: Parchment.Scope.INLINE,
  whitelist: ["solid", "double", "dotted"],
});
Quill.register(boxAttributor);

const atValues = [
  { id: 0, value: "barcode" },
  { id: 1, value: "customername" },
  { id: 2, value: "licensenumber" },
  { id: 3, value: "netweight" },
  { id: 4, value: "packageid" },
  { id: 5, value: "price" },
  { id: 6, value: "productname" },
  { id: 7, value: "supplierid" },
];
const excludedFonts = [
  "tuckers",
  "winterLove",
  "alishader",
  "serendipity",
  "bluehill",
  "bunchOfFlowers",
  "christmasInk",
  "fairygod",
  "misteriosDelAmor",
  "soybeanut",
  "spaceCraft",
  "helloPatty",
  "jathafaDemo"
];
// Filter the fonts to exclude those in the excludedFonts array
const filteredFonts = Font.whitelist.filter(
  (font) => !excludedFonts.includes(font)
);

const CustomToolbar = () => (
  <div id="tool">
    <button className="ql-indent" value="+1" />
    <button className="ql-underline" />
    <button className="ql-italic" />
    <select className="ql-font">
      {filteredFonts.map((font, index) => (
        <option
          key={index}
          value={font}
          defaultValue={font.includes("verdana")}
        >
          {font[0].toUpperCase() + font.substr(1)}
        </option>
      ))}
    </select>
    <select className="ql-size">
      {Size.whitelist.map((size, index) => {
        const sizeWithoutPx = size.replace("px", "");
        return (
          <option value={size} selected={size.includes("12")}>
            {sizeWithoutPx}
          </option>
        );
      })}
    </select>
  </div>
);
const excludedFont = [
  "dutchTulips",
  "quickgram",
  "crackerBanana",
  "nursery",
  "madeEvolve",
  "fairygod",
  "misteriosDelAmor",
  "soybeanut",
  "spaceCraft",
  "helloPatty",
  "jathafaDemo"
];

// Filter the fonts to exclude those in the excludedFonts array
const filteredFont = Font.whitelist.filter(
  (font) => !excludedFont.includes(font)
);

const CustomFontToolbar = () => (
  <div id="tolbar">
    <select className="ql-font">
      {filteredFont.map((font, index) => (
        <option
          key={index}
          value={font}
          defaultValue={font.includes("verdana")}
        >
          {font[0].toUpperCase() + font.substr(1)}
        </option>
      ))}
    </select>
    <select className="ql-size">
      {Size.whitelist.map((size, index) => {
        const sizeWithoutPx = size.replace("px", "");
        return (
          <option key={index} value={size} defaultValue={size.includes("12")}>
            {sizeWithoutPx}
          </option>
        );
      })}
    </select>
  </div>
);

const modules_font = {
  toolbar: {
    container: "#tolbar",
  },
};
const customBindings = {
  space: {
    key: ' ',
    handler: function(range, context) {
      // Insert a space at the current cursor position
      this.quill.insertText(range.index, ' ');

      // Update the cursor position
      this.quill.setSelection(range.index + 1);
    },
  },
};
const modules = {
  toolbar: {
    container: "#tool",
    keyboard: {
      bindings: customBindings,
    },
  },
  keyboard: {
    bindings: Quill.import('modules/keyboard').bindings,
  },
};

const formats = [
  "bold",
  "underline",
  "italic",
  "font",
  "size",
  "indent",
  "box",
  // 'mention'
];

function Blog_creation(props) {
  const [form] = Form.useForm();
  // const {location} = props
  const [ids, setid] = useState("");
  const [form1] = Form.useForm();
  const [text_value, setTextValue] = useState("");
  const [show, setshow] = useState(false);
  // const [success,setsuccess] = useState([])
  const [getdatas, setgetdatas] = useState([]);
  const [title_value, settitle_value] = useState("");
  const [image, setImage] = useState("");
  const [success, setsuccess] = useState(false);
  const [isUpdate, setIsUpdate] = useState(false);
  const [forImageApi, setForImageApi] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [pageName, setPageName] = useState("blog");
  const [deleteLoader, setDeleteLoader] = useState(false);
  const [quillInstance, setQuillInstance] = useState(null);
  const [quillIntanceFont, setQuillInstanceFont] = useState(null);
  const [activeFormats, setActiveFormats] = useState([]);
  const [selectedFont, setSelectedFont] = useState("Serif");
  const [selectedFontSize, setSelectedFontSize] = useState(16);
  const [quillMessage, setQuillMessage] = useState("");
  const [selectedYear, setSelectedYear] = useState(null);
  const [place, setPlace] = useState("");
  const [isNoteAvailable, setIsNoteAvailable] = useState(false);
  const [quillInstance_note, setQuillInstanceNote] = useState(null);
  const [noteText, setNoteText] = useState("");
  const currentYear = new Date().getFullYear();
  const [fontTitle_Change, setFontTitle_Change] = useState("");
  const [titleError, setTitleError] = useState("");

  const [selectedColor, setSelectedColor] = useState("#EBE2FC"); // Default color

  // Add more colors if needed
  const colorOptions = [
    { label: "Primary", value: "#EBE2FC" },
    { label: "Second", value: "#d6dff7" },
  ];
  // Handle color change
  const handleColorChange = (color) => {
    setSelectedColor(color);
    // You can also save the color to the database here if needed
  };

  const dispatch = useDispatch();

  function inPut_note_change(e) {
    setNoteText(e);
    return;
  }

  function fontTextChange(e) {
    setFontTitle_Change(e);
    return;
  }
  useEffect(() => {
    axios
      .post(`${API_URL}/getAllPosts`)
      .then((res) => {
        // setsuccess(true)
        let sortedPost = res.data.blogInfo.sort((a, b) =>
          b.created_at
            .split("/")
            .reverse()
            .join()
            .localeCompare(a.created_at.split("/").reverse().join())
        );
        setgetdatas(sortedPost);
      })
      .catch(() => {
        alert("something went wrong");
      });
  }, []);

  const disabledDate = (current) => {
    // Disable dates in the future
    return current && current.year() > moment().year();
  };

  const handleYearChange = (date, dateString) => {
    const dateSelected = new Date(dateString);
    setSelectedYear(dateSelected.getFullYear());
  };

  const handleNextLine = () => {
    if (quillInstance) {
      const cursorPosition = quillInstance.getSelection().index || 0;
      quillInstance.insertText(cursorPosition, "\n");
    }
  };

  const handleNextLineWithSpace = () => {
    if (quillInstance) {
      const cursorPosition = quillInstance.getSelection().index || 0;
      quillInstance.insertText(cursorPosition, "\n\n");
    }
  };

  const handleTextFormatting = (format, value = null) => {
    if (quillInstance) {
      if (format === "size" && value) {
        const range = quillInstance.getSelection();
        if (range) {
          quillInstance.getEditor().format(format, value);
        }
      } else {
        // Handle other text formatting options (bold, italic, underline, etc.)
        const isActive = activeFormats.includes(format);
        let updatedFormats = [...activeFormats];

        if (isActive) {
          // Format is active, so remove it
          const index = updatedFormats.indexOf(format);
          updatedFormats.splice(index, 1);
        } else {
          // Format is not active, so add it
          updatedFormats.push(format);
        }

        quillInstance.format(format, !isActive);
        setActiveFormats(updatedFormats);
      }
    }
  };

  function change(e) {
    return;
  }
  async function onFinish(e) {
    setshow(false);
    return;
  }
  function onFinishFailed(e) {
    return;
  }

  function onFinishBlogs(e) {
    const quillContent = quillInstance.getContents(); // Get the latest editor content
    const htmlContent = quillInstance.root.innerHTML; // Get the HTML content if needed
    const quillContents = isNoteAvailable
      ? quillInstance_note.getContents()
      : "";
    const htmlContents = isNoteAvailable
      ? quillInstance_note.root.innerHTML
      : "";
    const quillContentFonts = quillIntanceFont.getContents();
    const htmlContentFOnts = quillIntanceFont.root.innerHTML;
    if (quillContent && quillContent.ops[0].insert.length === 1) {
      // Handle empty quillContent, for example, display an error message
      setQuillMessage("Quote is required");
      // You can return or perform any necessary action based on the empty content
      return;
    }
    if (htmlContentFOnts.length <= 11) {
      setTitleError("Title is required");
      return;
    }
    const html = `
    <p class="story-fairy">Once upon a time</p>
      ${htmlContent}
    <p class="story-fairy">The End</p>
  `;
    dispatch(
      createBlogs(
        {
          ...e,
          description: quillContent,
          // If you need HTML content, you can send it as well
          descriptionHTML: htmlContent,
          // year:selectedYear
        },
        setsuccess,
        ids,
        setIsUpdate,
        isUpdate,
        forImageApi,
        setIsLoading,
        pageName,
        setDeleteLoader,
        text_value,
        quillContent,
        htmlContents,
        quillContents,
        html,
        quillContentFonts,
        htmlContentFOnts,
        selectedColor
      )
    ).then((res) => {
    });
  }

  function onFinishFailedblog(e) {
    return;
  }

  function handleQuillChange(e) {
    setTextValue(e);
    return;
  }

  if (show == false) {
    return (
      <div className="main-wrapper">
        <Link to={"/"}>
          <div className="back-button">
            <button onClick={() => {}} className="home_button">
              <HomeOutlined style={{ color: "black" }} size={20} />
            </button>
          </div>
        </Link>

        <div className="title-head text-center">
          <h1> Create Your Own Blog</h1>
        </div>
        <div
          className="blog-wrapper_s"
          style={{
            backgroundColor: "white",
            width: "98%",
            alignSelf: "center",
            margin: "auto",
            borderRadius: "15px",
            boxShadow: "initial",
          }}
        >
          <Form
            initialValues={{ remember: true }}
            name="blog_creation"
            form={form1}
            onFinish={onFinishBlogs}
            // onChange={}
            onFinishFailed={onFinishFailedblog}
            layout="inline"
            style={{ width: "80%", margin: "auto", paddingTop: "20px" }}
          >
            <div className="form-group">
              <label>Title</label>
              <FormItem
                rules={[
                  {
                    required: false,
                    message: "Title is required",
                  },
                ]}
                name={`title`}
              >
                <CustomFontToolbar />
                <ReactQuill
                  theme="snow"
                  placeholder="Type your story title here..."
                  value={fontTitle_Change}
                  onChange={fontTextChange}
                  modules={modules_font}
                  formats={formats}
                  ref={(quills) => {
                    if (quills) {
                      setQuillInstanceFont(quills.getEditor());
                    }
                  }}
                />
                <label style={{ color: "red", top: 20, fontSize: 12 }}>
                  {titleError}
                </label>
              </FormItem>
            </div>
            <div className="form-group">
              <label>Image</label>
              <FormItem name={`image`}>
                <Input
                  type={`file`}
                  onChange={(e) => {
                    
                    setForImageApi(e.target.files[0]);
                  }}
                  accept=".jpg,.png,.jpeg"
                  placeholder="Please enter your title"
                  className="blog-inputs"
                />
              </FormItem>
            </div>
            <div className="form-group">
              <label>Year</label>
              <FormItem
                rules={[
                  {
                    validator: (_, value) => {
                      if (!value) {
                        return Promise.reject("Year is required");
                      }

                      const inputYear = parseInt(value, 10);

                      if (isNaN(inputYear)) {
                        return Promise.reject("Please enter a valid year");
                      }

                      if (inputYear > currentYear) {
                        return Promise.reject(
                          "Year cannot be greater than the current year"
                        );
                      }

                      return Promise.resolve();
                    },
                  },
                ]}
                validateFirst
                name={`year`}
              >
                {/* <DatePicker
                    picker="year"
                    value={selectedYear}
                    disabledDate={disabledDate}
                    onChange={handleYearChange}
                    placeholder="Select year"
                    className="blog-inputs"
                  /> */}
                <Input
                  value={selectedYear}
                  onChange={(e) => e.target.value}
                  onKeyDown={(e) => {
                    const isValidKey =
                      /^[0-9]+$/.test(e.key) ||
                      e.key === "Backspace" ||
                      e.key === "Delete" ||
                      e.key === "ArrowLeft" ||
                      e.key === "ArrowRight";

                    if (!isValidKey) {
                      e.preventDefault();
                    }
                  }}
                  placeholder="Enter year"
                  className="blog-inputs"
                />
              </FormItem>
            </div>
            <div className="form-group w-100">
              <label>Quotes</label>
              <FormItem
                rules={[
                  {
                    required: false,
                    message: "Quote is required",
                  },
                ]}
                name={`Quote`}
              >
                <CustomToolbar />
                <ReactQuill
                  theme="snow"
                  value={text_value}
                  onChange={handleQuillChange}
                  modules={modules}
                  formats={formats}
                  ref={(quill) => {
                    if (quill) {
                      setQuillInstance(quill.getEditor());
                    }
                  }}
                />
                <label style={{ color: "red", top: 20, fontSize: 12 }}>
                  {quillMessage}
                </label>
                <div
                  className="text-center"
                  style={{
                    display: "flex",
                    paddingTop: 10,
                    gap: 10,
                    flexWrap: "wrap",
                    width: "80%",
                    marginBottom: 5,
                  }}
                >
                  <Button onClick={handleNextLine}>Move to Next Line</Button>
                  <Button onClick={handleNextLineWithSpace}>
                    Move to Next Para
                  </Button>
                  <Button
                    onClick={() => handleTextFormatting("bold")}
                    style={{
                      color: activeFormats.includes("bold")
                        ? "blue"
                        : "inherit",
                    }}
                  >
                    <BoldOutlined size={20} />
                  </Button>
                  <Button
                    onClick={() => handleTextFormatting("italic")}
                    style={{
                      color: activeFormats.includes("italic")
                        ? "blue"
                        : "inherit",
                    }}
                  >
                    <ItalicOutlined size={20} />
                  </Button>
                  <Button
                    onClick={() => handleTextFormatting("underline")}
                    style={{
                      color: activeFormats.includes("underline")
                        ? "blue"
                        : "inherit",
                    }}
                  >
                    <UnderlineOutlined size={20} />
                  </Button>
                </div>
              </FormItem>
            </div>
            {isNoteAvailable ? (
              <>
              <StickyNote
                quillInstance_note={quillInstance_note}
                setQuillInstanceNote={setQuillInstanceNote}
                inPut_note_change={inPut_note_change}
                noteText={noteText}
              />
              <div className="sticky-toolbar">
              <label htmlFor="background-color">
                Choose Note Background Color:
              </label>
              <div style={{display:'flex',flexDirection:'row',marginBottom:12}}>
              <select
                id="background-color"
                value={selectedColor}
                onChange={(e) => handleColorChange(e.target.value)}
              >
                {colorOptions.map((colorOption) => (
                  <option key={colorOption.value} value={colorOption.value}>
                    {colorOption.label}
                  </option>
                ))}
              </select>
              <div
                className="color-preview"
                style={{ backgroundColor: selectedColor }}
              ></div>
              </div>
            </div>
            </>
            ) : null}
            <div className="form-group w-100 text-center">
              <Button
                type="primary"
                className="login_submit_button"
                htmlType="submit"
              >
                {isLoading === true ? <Spin indicator={antIcon} /> : ""}
                {isUpdate === true ? `Update a Post` : "Create a post"}
              </Button>
            </div>
          </Form>
        </div>
        {success == true ? (
          <Navigate
            to={{
              pathname: "/",
            }}
          />
        ) : (
          ""
        )}
        <div className="App">
          {getdatas.length > 0
            ? getdatas
                .filter(
                  (item) =>
                    item.pageName === "blog" ||
                    item.pageName === "reflections" ||
                    item.pageName === "poems"
                )
                .map((item) => {
                  let date = item.created_at.split(" ")[0].split("-");
                  const month = [
                    "January",
                    "February",
                    "March",
                    "April",
                    "May",
                    "June",
                    "July",
                    "August",
                    "September",
                    "October",
                    "November",
                    "December",
                  ];
                  date = `${
                    date[1] > 9 ? month[date[1] - 1] : month[date[1][1] - 1]
                  }   ${date[2] > 9 ? date[2] : `0${date[2]}`} ${date[0]}`;

                  return (
                    <div>
                      <div className="container-fluid post-container">
                        <div className="row">
                          <div
                            className={`site-content `}
                            style={{ paddingTop: 10 }}
                          >
                            <div
                              class={`post-sec frame1`}
                              style={{
                                backgroundColor: "#FDFAF6",
                                width: "100%",
                                borderRadius: 15,
                              }}
                            >
                              <div
                                style={{
                                  display: `flex`,
                                  justifyContent: `flex-end`,
                                }}
                              >
                                {deleteLoader === true ? (
                                  <Spin
                                    indicator={deleteLoadIcon}
                                    style={{ paddingRight: 10, color: "#0000" }}
                                  />
                                ) : (
                                  ""
                                )}
                                <div>
                                  <img
                                    src={editimage}
                                    id={item.id}
                                    style={{
                                      width: `20px`,
                                      marginRight: `10px`,
                                      cursor: `pointer`,
                                    }}
                                    onClick={(e) => {
                                      const editedDescription = item.description
                                        .split(/\r?\n|\r|\n/g)
                                        .slice(1)
                                        .slice(0, -1)[0];
                                      {
                                        isUpdate === true
                                          ? form1.setFieldsValue({
                                              title: item.title,
                                              Quote:
                                                item.pageName === "fairytale"
                                                  ? item.description
                                                      .split(/\r?\n|\r|\n/g)
                                                      .slice(1)
                                                      .slice(0, -1)[0]
                                                  : item.description,
                                              pagename: item.pageName,
                                              year: item.year,
                                              place: item.place,
                                            })
                                          : form1.setFieldsValue({
                                              title: item.title,
                                              Quote: item.description,
                                              place: item.place,
                                              year: item.year,
                                            });
                                      }
                                      {
                                        item.note !== null
                                          ? (setIsNoteAvailable(true),
                                            setNoteText(item.note))
                                          : setIsNoteAvailable(false);
                                      }

                                      setIsUpdate(true);
                                      setImage(item.imageUrl);
                                      setTextValue(
                                        item.pageName === "fairytale"
                                          ? editedDescription
                                          : item.description
                                      );
                                      settitle_value(item.title);
                                      setForImageApi(item.imageUrl);
                                      setPlace(item.place);
                                      setFontTitle_Change(item.title);
                                      setSelectedYear(item.year);
                                      setSelectedColor(item.background_colour);
                                      setid(e.target.id);
                                      setPageName(item.pageName);
                                      document.body.scrollTop = 0;
                                      document.documentElement.scrollTop = 0;
                                    }}
                                    alt=""
                                  />
                                </div>
                                <div>
                                  <img
                                    src={deleteimage}
                                    style={{ cursor: `pointer`, width: `20px` }}
                                    id={item.id}
                                    onClick={(e) => {
                                      setDeleteLoader(true);
                                      axios
                                        .post(`${API_URL}/deletePost`, {
                                          id: e.target.id,
                                        })
                                        .then((res) => {
                                          setDeleteLoader(false);
                                          dispatch(getBlogs(setsuccess));
                                          axios
                                            .post(`${API_URL}/getAllPosts`)
                                            .then((res) => {
                                              setgetdatas(res.data.blogInfo);
                                            })
                                            .catch(() => {
                                              alert("something went wrong");
                                            });
                                        });
                                      // setdeleteid(e.target.id)
                                    }}
                                    alt=""
                                  />
                                </div>
                              </div>
                              <div className="date-created-hidden">
                                <p>
                                  {moment(item.created_at)
                                    ?.utcOffset(0, true)
                                    .format("dddd, MMMM Do YYYY, h:mm:ss a")}
                                </p>
                              </div>
                              {(item?.pageName === "poems" &&
                                item.note !== null) ||
                              (item?.pageName === "reflections" &&
                                item.note !== null) ? (
                                <div
                                  className="note-container"
                                  style={{
                                    paddingTop: 10,
                                    display: "flex",
                                    flexDirection: "column",
                                  }}
                                >
                                  {item.title !== null ? (
                                    <h1
                                      class={`entry-title`}
                                      style={{ height: 80 }}
                                      dangerouslySetInnerHTML={{
                                        __html: item.title,
                                      }}
                                    ></h1>
                                  ) : null}

                                  {(item?.pageName === "poems" &&
                                    item.note !== null) ||
                                  (item?.pageName === "reflections" &&
                                    item.note !== null) ? (
                                    <>
                                      {item?.pageName === "poems" ||
                                      item?.pageName === "reflections" ? (
                                        <div
                                          style={{
                                            alignSelf: "flex-start",
                                            marginBottom: 30,
                                            width: "100%",
                                          }}
                                        >
                                          <Note note={item.note} bg_color={item.background_colour} />
                                        </div>
                                      ) : null}
                                    </>
                                  ) : null}
                                </div>
                              ) : (
                                <>
                                {item.title.length !== 11 ? (
                                  <h1
                                    class={`entry-title`}
                                    dangerouslySetInnerHTML={{
                                      __html: item.title,
                                    }}
                                  >
                                  
                                  </h1>
                                ) : null}
                                </>
                              )}
                              <div className="date-created">
                                <p>
                                  {moment(item.created_at)
                                    ?.utcOffset(0, true)
                                    .format("dddd, MMMM Do YYYY, h:mm:ss a")}
                                </p>
                              </div>
                              {item.imageUrl ? (
                                <img
                                  className={`image1`}
                                  src={item.imageUrl}
                                ></img>
                              ) : (
                                ""
                              )}
                              <div className="desc_wrapper-poems" style={{lineHeight:0,marginTop:30}}>
                                <div
                                  style={{
                                    
                                    color: `#000080`,
                                    width: "100%",
                                    display: "inline-block",
                                    whiteSpace: "pre-wrap",
                                    alignSelf: "center",
                                    lineHeight: "0px"
                                  }}
                                  className={`entry-content entry-contented ${props.bgparaValue}`}
                                  dangerouslySetInnerHTML={{
                                    __html: item.description.replace(
                                      /&nbsp;/g,
                                      " "
                                    ),
                                  }}
                                ></div>
                              </div>
                            </div>
                            {item?.pageName === "poems" ||
                            item?.pageName === "reflections" ||
                            item?.pageName === "blog" ? (
                              <div
                                className={`poem-reflection--create`}
                                style={{
                                  width: "93%",
                                  fontSize: `${props.parasize}px`,
                                  fontFamily: `${props.bgparaValue}`,
                                }}
                              >
                                <h>
                                  © {currentYear} All Rights Reserved.{"     "}
                                </h>
                                <h>
                                  {""}
                                  {item.place ? `${item.place},` : ""}{" "}
                                  {item.year ? item.year : ""}
                                </h>
                              </div>
                            ) : null}
                          </div>
                        </div>
                      </div>
                      <div class="separator separator12"></div>
                    </div>
                  );
                })
            : ""}
        </div>
      </div>
    );
  }
  return (
    <>
      <Modal className="login_modal" title="Login" footer={null} open={true}>
        <Form
          initialValues={{ remember: true }}
          name="forget_password"
          form={form}
          onFinish={onFinish}
          onChange={change}
          onFinishFailed={onFinishFailed}
          layout="inline"
        >
          <div className="login_form">
            <label>Password</label>
            <FormItem
              rules={[
                {
                  message: "password seems wrong",
                  validator: (_, value) => {
                    if (value == "jd@1234") {
                      return Promise.resolve();
                    } else {
                      return Promise.reject("password not match");
                    }
                  },
                },
              ]}
              name={`password`}
            >
              <Input
                type={`password`}
                placeholder="Please enter your password"
              />
            </FormItem>
          </div>

          <Button
            type="primary"
            className="login_submit_button"
            htmlType="submit"
          >
            Submit
          </Button>
        </Form>
      </Modal>
    </>
  );
}
export default Blog_creation;
