import axios from "axios";
import { API_URL } from "../config";
import api from "../utils/api";
import { ADD_TAGS_LIST, FETCH_TAGS_LIST,FETCH_TAGS_LIST_SUCCESS } from "./actionTypes";


export const fetchTagList = () => async dispatch => {
    try {
      const res = await api.get(`${API_URL}/taglist`);
      dispatch({
        type: FETCH_TAGS_LIST,
        payload: res.data.data
      });
    } catch (err) {
      if (!window.navigator.onLine) {
        throw new Error("Cannot reach server, Please check your internet connection")
      }
      else {
        throw new Error("Something went wrong, please try again")
      } 
    }
  }


  export const addTagList = (setsuccess) => async dispatch => {
    const pageName = "poems";
    try {
      const res = await api.get(`${API_URL}/addtag`);
      dispatch({
        type: ADD_TAGS_LIST,
        payload: res.data.data
      });
      setsuccess(true)
    } catch (err) {
      if (!window.navigator.onLine) {
        throw new Error("Cannot reach server, Please check your internet connection")
      }
      else {
        throw new Error("Something went wrong, please try again")
      } 
    }
  }
