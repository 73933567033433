import React, { useCallback, useEffect, useState } from "react";
import { Modal,Form,Input,Button, Card, Dropdown, Menu, Tag,Spin} from 'antd';
import {CloseOutlined, LockOutlined, FilterOutlined,DownOutlined, SearchOutlined} from '@ant-design/icons';
import "./App.css";
import { useNavigate } from "react-router-dom";
import images_nodata from "../src/images/new-menu-bg/images_nodata.png";
import { connect, useDispatch, useSelector } from "react-redux";
import ContentBlogLoader from "./Components/Loader/contentLoader";
import BodyBlogLoader from "./Components/Loader/bodyLoader";
import { fetchPoems } from "./Actions/poems";
import Note from "./utils/Note";
import DOMPurify from "dompurify"; 
import axios from "axios";
import { API_URL } from "./config";
import logos from "../src/images/menu-frame/new_lg.PNG"

function DemoThree({
  parasize,
  menuvalue,
  font_type,
  bgValue,
  bgparaValue,
  colorFont,
  poems,
  lineHeight,
  setIsReflectionOrPoem,
  setShowMOdalNew,
  showModalNew,
}) {
  const [success, setsuccess] = useState([]);
  const [getdatas, setgetdatas] = useState([]);
  const currentYear = new Date().getFullYear();
  const [selectedTags, setSelectedTags] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [tagOptions, setTagOptions] = useState([]);  // Store tags dynamically from API
  const [loadingTags, setLoadingTags] = useState(false);
  let navigate = useNavigate();
  const dispatch = useDispatch();
  console.log("the background printed",bgparaValue);
  useEffect(() => {
    dispatch(fetchPoems(setsuccess));
    setIsReflectionOrPoem(true);
  }, []);

  useEffect(() => {
    if (poems?.length !== 0) {
      setgetdatas(poems);
      setsuccess(true);
    }
  }, [poems]);

    // Fetch tags on component mount
    useEffect(() => {
      fetchTags();
    }, []);
  
    const fetchTags = async () => {
      setLoadingTags(true);
      try {
        const response = await axios.get(`${API_URL}/taglist`);
        if (response.data.result === "success") {
          setTagOptions(response.data.data.map(tag => tag.tag_name));  // Store tag names dynamically
        }
      } catch (error) {
        console.error("Error fetching tags:", error);
      } finally {
        setLoadingTags(false);
      }
    };

  const filteredBlogs = getdatas.filter((poem) => {
    const titleMatch = DOMPurify.sanitize(poem.title)
      .toLowerCase()
      .includes(searchTerm.toLowerCase());
    const tagsMatch = selectedTags.length === 0 || selectedTags.some(tag => poem.tags?.includes(tag));
    return titleMatch && tagsMatch;
  });

  const handleSearch = (value) => {
    setSearchTerm(value);
  };
  const handleMenuClick = (tag) => {
    setSelectedTags(prevTags => 
      prevTags.includes(tag) ? prevTags.filter(t => t !== tag) : [...prevTags, tag]
    );
  };

// Dynamic tag menu
const menu = (
  <Menu className="custom-menu">
    {loadingTags ? (
      <Menu.Item key="loading">
        <Spin size="small" />
      </Menu.Item>
    ) : (
      tagOptions.map((tag) => (
        <Menu.Item
          key={tag}
          onClick={() => handleMenuClick(tag)}
          className="menu-item"
        >
          {selectedTags.includes(tag) ? <Tag color="blue">{tag}</Tag> : tag}
        </Menu.Item>
      ))
    )}
  </Menu>
);



  if (success == false) {
    return (
      <React.Fragment>
        <div className="contentLoader">
          <ContentBlogLoader />
        </div>
        <div className="skeletonLoader">
          <BodyBlogLoader />
        </div>
        <div className="skeletonLoader">
          <BodyBlogLoader />
        </div>
        <div className="skeletonLoader">
          <BodyBlogLoader />
        </div>
      </React.Fragment>
    );
  }
  return (
    <React.Fragment>
    <div className="main_searchBox" style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' ,gap: '8px' }}>
    {/* Logo Section */}
    <div className="logo-container-new" style={{ cursor: 'pointer' }} onClick={()=> setShowMOdalNew(!showModalNew)}>
      <img src={logos} alt="Logo" width={90} height={90} className="logo-image"/>
    </div>
  
    {/* Search Box and Filter Section */}
    <div style={{display:'flex',flexDirection:'column'}}>
    <div className="inner_searchBox" style={{ display: 'flex', alignItems: 'center', flex: 1, gap: '10px' }}>
      {/* Search Input */}
      <Input.Search
        placeholder="Search qualities or select tags"
        onSearch={handleSearch}
        allowClear
        prefix={<SearchOutlined style={{ color: '#0F307A' }} />}
        className="searchBoxSpan"
        style={{
          flex: 1,
          maxWidth: '600px',
          // height: '40px',
          margin: "auto",
        }}
      />
  
      {/* Filter Dropdown */}
      <Dropdown overlay={menu} trigger={['click']}>
        <Button
          type="text"
          style={{
            color: '#0F307A',
            fontSize: '16px',
            padding: '0 12px',
            fontWeight: '500',
            display: 'inline-flex',
            alignItems: 'center',
            backgroundColor: "#fff",
            height: '40px',
          }}
        >
          <FilterOutlined style={{ marginRight: '4px' }} />
          <DownOutlined />
        </Button>
      </Dropdown>
    </div>
  
    {/* Tags Section */}
    {/*selectedTags.length > 0 && (
    <div style={{
      position: 'absolute',
      top: 80,  // Adjust based on the position of the Search Box and Filter icon
      left: '50%',
      transform: 'translateX(-50%)',
      zIndex: 999,
      backgroundColor: '#fff',
      padding: '10px',
      width: 'calc(100% - 40px)',  // Adjust to fit the width
      boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)',
    }}>
    <div style={{ marginTop: '10px', flexWrap: 'wrap', display: 'flex', gap: '5px' }}>
      {selectedTags.map((tag) => (
        <Tag
          key={tag}
          color="blue"
          closable
          onClose={() => handleMenuClick(tag)}
          style={{
            fontSize: '14px',
            padding: '4px 12px',
            borderRadius: '16px',
            display: 'inline-flex',
            alignItems: 'center',
            boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
          }}
        >
          {tag}
        </Tag>
      ))}
    </div>
        </div>)*/}
    </div>
  </div>
  
    <div className="App"  style={{overflowX:'auto',marginTop:100}}>
    {success == true
      ? filteredBlogs.map((item) => {
          return (
            <React.Fragment key={item.id}>
              <div className="container-fluid post-container">
                <div className="row">
                  <div className={`site-content ${font_type}`}>
                    <div className={`post-sec frame1 ${bgValue}`}>
                      {item?.pageName === "poems" && item.note !== null ? (
                        <div
                          className="note-container"
                          style={{
                            paddingTop: 0,
                            display: "flex",
                            flexDirection: "column",
                          }}
                        >
                          {item.title !== null ? (
                            <div className="title-div-poem-note">
                              <h1
                                className="entry-title"
                                dangerouslySetInnerHTML={{
                                  __html: item.title,
                                }}
                              ></h1>
                            </div>
                          ) : null}
                          {item?.pageName === "poems" && item.note !== null ? (
                            <div className="sticky-box">
                              <Note
                                note={item.note}
                                bg_color={item.background_colour}
                              />
                            </div>
                          ) : null}
                        </div>
                      ) : (
                        <div className="title-div-poem">
                          {item.title.length !== 11 ? (
                            <div>
                              <h1
                                className="entry-title"
                                dangerouslySetInnerHTML={{
                                  __html: item.title,
                                }}
                              ></h1>
                            </div>
                          ) : null}
                        </div>
                      )}
                      {item.imageUrl ? (
                        <img className={`image1`} src={item.imageUrl}></img>
                      ) : (
                        ""
                      )}
                      <div className="desc_wrapper-poems" style={{lineHeight:0}}>
                        <div
                          style={{
                            fontSize: `${parasize}px`,
                            color: `${
                              item.fairytale_content_colour
                                ? item.fairytale_content_colour
                                : "#0F307A"
                            }`,
                            width: "100%",
                            display: "inline-block",
                            whiteSpace: "pre-wrap",
                            alignSelf: "center",
                            lineHeight: "0px"
                          }}
                          className={`entry-content ${bgparaValue} ${
                            item.space_number ? `entry-content_poem` : ""
                          }`} 
                          dangerouslySetInnerHTML={{
                            __html: item.description.replace(/&nbsp;/g, " "),
                          }}
                        ></div>
                      </div>
                    </div>
                    {item?.pageName === "poems" ? (
                      <div
                        className="poem-reflection-footer"
                        style={{
                          fontSize: 12,
                          fontFamily: `${bgparaValue}`,
                        }}
                      >
                        <h>
                          © {currentYear} Ritu Dhillon All Rights Reserved.
                        </h>
                        <h>
                          {item.place ? `${item.place},` : ""}{" "}
                          {item.year ? item.year : ""}
                        </h>
                      </div>
                    ) : null}
                  </div>
                </div>
              </div>
              <div className="separator"></div>
            </React.Fragment>
          );
        })
      : ""}    
      {filteredBlogs.length === 0 ? (
        <div
          className=""
          style={{
            alignSelf: "center",
            marginTop: 100,
            marginLeft: "auto",
            marginRight: "auto",
            display: "flex",
            flexDirection: "column",
          }}
        >
          <img
            src={images_nodata}
            alt=""
            width={100}
            height={100}
            style={{ margin: "auto" }}
          />
          <label
            style={{
              alignSelf: "center",
              marginTop: 30,
              marginLeft: "auto",
              marginRight: "auto",
              display: "flex",
              flexDirection: "column",
            }}
          >
            No Poems found!
          </label>
        </div>
      ) : (
        ""
      )}
    </div>
    </React.Fragment>
  );
}

const mapStateToProps = (state) => ({
  poems: state.enroll.poems,
});

export default connect(mapStateToProps)(DemoThree);
